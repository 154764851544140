.v--modal-overlay {
  /*  background: rgba(54, 71, 96, 0.17);
    z-index: 1024;*/

  /* z-index: 999; */
}

.vm--overlay {
  width: 100%;
  background: rgba(54, 71, 96, 0.17);
  position: sticky;
}

.vm--modal {
  position: absolute;
  height: auto !important;
  /* top: 30px !important;*/
  border-radius: 6px 6px 6px 6px;
}

.vm--block-scroll {
  overflow: hidden !important;
  width: 100vw;
  height: 100vh;
}

.vm--container {
  z-index: 99999 !important;
}

.vue-dialog {
  border-radius: 10px 10px 0 0;
  .card {
    border: none;
    margin-bottom: 0;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    .card-header {
      /*background: #364760;*/
      color: #ffffff;
      font-family: 'Titillium Web', sans-serif;
      font-size: 18px;
      min-height: 43px;

      /* Light / White */

      background: #FFFFFF;
      /* Shadow */

      box-shadow: 0px 8px 24px 2px rgba(54, 59, 100, 0.08);
      border-radius: 10px 10px 0px 0px;
      border-bottom: none;

      padding: 0.39rem 1rem 0.4rem 1rem;
      .card-title {
        /* color: #d0dcef;*/
        color: #304056;
        font-family: 'Titillium Web', sans-serif;
        font-size: 17px;
        font-weight: 600;
        min-height: 0;
        text-transform: none;
      }
    }

    .col-form-label {
      text-align: right;
      font-family: 'Titillium Web', sans-serif;
      font-size: 15px;
      font-weight: 600;
      padding-top: calc(0.355rem + 1px);
      padding-bottom: calc(0.385rem + 1px);
      /*text-transform: uppercase;*/
    }

    .form-control {
      height: 2.375rem;
      padding: 0.355rem 0.75rem 0.385rem 0.75rem;
      font-size: 0.9375rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fafafa;
      background-clip: padding-box;
      border: 1px solid #e7ebf1;
      border-radius: 2px;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      box-shadow: none !important;
      -webkit-appearance: none !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      font-family: 'Titillium Web', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }

    textarea.form-control {
      height: auto;
    }

    .card-body {
      padding: 3.2rem 1.5rem 3.52rem 1.5rem;
    }
  }

  .vue-dialog-buttons {
    border-top: none !important;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    box-shadow: none !important;
    .vue-dialog-button {
      box-shadow: none !important;
      -webkit-appearance: none !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      font-family: 'Titillium Web', sans-serif;
      font-size: 14px !important;
      padding: 0.345rem 0.75rem 0.395rem 0.75rem;
      text-transform: uppercase;
      font-weight: 600;
      &.vue-dialog-button-cancel {
        /*        background: #e9573f;
                border-color: #e9573f;  */
        /*        background: #F2994A;
                border-color: #F2994A;*/
        background: #ff5722;
        border-color: #ff5722;
        color: #ffffff;
        transition: .3s background;

        .svg-inline--fa {
          margin-right: 4px;
        }

        &:hover {
          background: #e2400e;
          border-color: #e2400e;
          color: #ffffff;
        }
      }

      &.vue-dialog-button-save {
        /*        background-color: #8cc152;
                border-color: #8cc152;*/
        /*        background-color: #363B64;
                border-color: #363B64;*/
        /*        background-color: #4b5276;
                border-color: #4b5276;*/

        /*        background: #304056;
                background-image: linear-gradient(
                    135deg
                  , #304056, #595b87) !important;

                border-color: #304056;*/

        /* background-image: linear-gradient(to right, #4b6cb7 0%, #182848  51%, #4b6cb7  100%);
 */
        background: #6f73ad;
        border-color: #6f73ad;

        color: #ffffff;
        transition: .3s background;

        .svg-inline--fa {
          margin-right: 4px;
        }

        &:hover {
          /*          background-image: linear-gradient(
                        135deg
                      , #595b87, #304056) !important;*/

          /*          border-color: #304056;
                    border-left-color: #595b87;*/
          background: #5a629c;
          border-color: #5a629c;
          color: #fff;
          text-decoration: none;
        }
      }

      &.vue-dialog-button-continue {
        background-color: #525692;
        border-color: #525692;
        color: #ffffff;

        transition: .3s background;

        .svg-inline--fa {
          margin-right: 4px;
        }

        &:hover {
          background-color: #44488b;
          border-color: #44488b;
          color: #ffffff;
        }
      }
    }
  }

  .dialog-tabs {
    .card-header {
      z-index: 10;
      .card-title {

      }
    }
    .card-body {
      padding: 0;
      .vue-tabs {
        .nav-tabs-navigation {
          padding: 6px 20px 5px 20px;
          background: #304056;
          background-image: linear-gradient(
              90deg
            , #fafaff, #f7f8ff) !important;
          .nav-tabs-wrapper {
            .nav-pills {
              .tab {
                position: relative;
                a {
                  border-radius: 4px;
                  text-align: left;
                  font-family: 'Titillium Web', sans-serif;
                  font-size: 13px;
                  font-weight: 600;
                  padding: 34px 12px 7px 12px;
                  color: #5a629c;
                  min-width: 80px;
                  span {
                    &.title {
                      justify-content: center;
                      text-align: center;
                      padding-top: 4px;
                      svg {
                        font-size: 22px;
                        position: absolute;
                        top: 12px;
                        margin: 0 auto;
                      }
                    }
                  }
                  &:hover {
                    background: none;
                    color: #363b63;
                  }
                }
                &.active {
                  .active_tab {
                    background: none !important;
                    color: #FFFFFF !important;
                    .title {
                      /*color: #363b63 !important;*/
                      color: #fc9f28 !important;
                    }
                  }
                }
                &:after {
                  content: " ";
                  height: 80%;
                  width: 1px;
                  display: block;
                  background: #6168a5;
                  position: absolute;
                  top: 10%;
                  right: -2px;
                  opacity: 0.2;
                }
                &:last-child {
                  &:after {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .tab-content {
          padding: 5px 20px 30px 20px;
          .tab-container {
            .tab-content-wrap {

            }
          }
        }
      }
    }
  }
}


.ck-editor__editable_inline {
  min-height: 200px;
  width: 100%;
}
