.ipsAction {
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  position: relative;
  padding: 5px 20px 4px 20px;
  padding-right: 60px;
  background: #f0f0f0;
  border: none;
  color: #333333;
  transition: .2s;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  font-weight: bold;
  font-size: 12px;

  .btn-icon {
    &.btn-icon-right {
      position: absolute;
      right: 15px;
      top: 5px;
    }
  }

  &:before {
    background: rgba(0, 0, 0, 0.1);
  }

  &:before, &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding-top: inherit;
    padding-bottom: inherit;
    width: 40px;
    content: "\00a0";
    font-family: 'FontAwesome', sans-serif;
    font-size: 1.1em;
    text-align: center;
    transition: .2s;
    transform-origin: 50% 60%;
  }


  &:hover, &:focus, &:active {
    text-decoration: none !important;
    background: #e5e5e5;
    color: #000000;

    .btn-icon {
      &.btn-icon-right {
        position: absolute;
        right: 17px;
        top: 5px;
        -webkit-animation: bounceright .3s alternate ease infinite;
        animation: bounceright .3s alternate ease infinite;
      }
    }

    &:after {
      -webkit-animation: bounceright .3s alternate ease infinite;
      animation: bounceright .3s alternate ease infinite;
      text-decoration: none;
    }

  }

  &.ipsAction-150 {
    min-width: 150px;
  }

}

.data-flag {
  position: relative;
}

.st-count-wrap {
  margin-top: 2px;
  padding: 0;
  width: 100%;
  float: left;
  margin-bottom: 3px;
  .st-count {
    padding: 1px 2px 1px 2px;
    min-width: 50px;
    text-align: center;
    background: #EEEEEE;
    display: block;
    float: left;
    color: #333333;
    font-size: 12px;
    font-weight: bold;
    &.st-count-total {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &.st-active {
      background: #defabb;
      font-weight: normal;
    }
    &.st-inactive {
      background: #FFCDD2;
      font-weight: normal;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &.st-active-null {
      font-weight: normal;
      background: #f5f2f2;
      color: #d7d7d7;
    }
    &.st-inactive-null {
      font-weight: normal;
      background: #f7f7f7;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      color: #d7d7d7;
    }
  }
}
