.vue-tabs.stacked {
  display: flex; }

.vue-tabs .tabs__link {
  text-decoration: none;
  color: gray; }

.vue-tabs .nav {
  margin-bottom: 0;
  margin-top: 0;
  padding-left: 0;
  list-style: none; }
  .vue-tabs .nav:before, .vue-tabs .nav:after {
    content: " ";
    display: table; }
  .vue-tabs .nav:after {
    clear: both; }
  .vue-tabs .nav > li {
    position: relative;
    display: block; }
    .vue-tabs .nav > li > a {
      position: relative;
      display: block;
      padding: 10px 15px; }
      .vue-tabs .nav > li > a:hover, .vue-tabs .nav > li > a:focus {
        text-decoration: none;
        background-color: #eeeeee; }
    .vue-tabs .nav > li span.title {
      display: flex;
      justify-content: center; }
    .vue-tabs .nav > li.disabled > a {
      color: #777777; }
      .vue-tabs .nav > li.disabled > a:hover, .vue-tabs .nav > li.disabled > a:focus {
        color: #777777;
        text-decoration: none;
        cursor: not-allowed;
        background-color: transparent;
        border-color: transparent; }
  .vue-tabs .nav .nav-divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5; }
  .vue-tabs .nav > li > a > img {
    max-width: none; }

.vue-tabs .nav-tabs {
  border-bottom: 1px solid #ddd; }
  .vue-tabs .nav-tabs > li {
    float: left;
    margin-bottom: -1px; }
    .vue-tabs .nav-tabs > li > a {
      margin-right: 2px;
      line-height: 1.42857;
      border: 1px solid transparent;
      border-radius: 4px 4px 0 0; }
      .vue-tabs .nav-tabs > li > a:hover {
        border-color: #eeeeee #eeeeee #ddd; }
    .vue-tabs .nav-tabs > li.active > a, .vue-tabs .nav-tabs > li.active > a:hover, .vue-tabs .nav-tabs > li.active > a:focus {
      color: #555555;
      background-color: #fff;
      border: 1px solid #ddd;
      border-bottom-color: transparent;
      cursor: default; }

.vue-tabs .nav-pills > li {
  float: left; }
  .vue-tabs .nav-pills > li > a {
    border-radius: 4px; }
  .vue-tabs .nav-pills > li + li {
    margin-left: 2px; }
  .vue-tabs .nav-pills > li.active > a, .vue-tabs .nav-pills > li.active > a:hover, .vue-tabs .nav-pills > li.active > a:focus {
    color: #fff;
    background-color: #337ab7; }

.vue-tabs .nav-stacked > li {
  float: none; }
  .vue-tabs .nav-stacked > li + li {
    margin-top: 2px;
    margin-left: 0; }

.vue-tabs .nav-justified, .vue-tabs .nav-tabs.nav-justified {
  width: 100%; }
  .vue-tabs .nav-justified > li, .vue-tabs .nav-tabs.nav-justified > li {
    float: none; }
    .vue-tabs .nav-justified > li > a, .vue-tabs .nav-tabs.nav-justified > li > a {
      text-align: center;
      margin-bottom: 5px; }
  .vue-tabs .nav-justified > .dropdown .dropdown-menu {
    top: auto;
    left: auto; }
  @media (min-width: 768px) {
    .vue-tabs .nav-justified > li, .vue-tabs .nav-tabs.nav-justified > li {
      display: table-cell;
      width: 1%; }
      .vue-tabs .nav-justified > li > a, .vue-tabs .nav-tabs.nav-justified > li > a {
        margin-bottom: 0; } }

.vue-tabs .nav-tabs-justified, .vue-tabs .nav-tabs.nav-justified {
  border-bottom: 0; }
  .vue-tabs .nav-tabs-justified > li > a, .vue-tabs .nav-tabs.nav-justified > li > a {
    margin-right: 0;
    border-radius: 4px; }
  .vue-tabs .nav-tabs-justified > .active > a, .vue-tabs .nav-tabs.nav-justified > .active > a,
  .vue-tabs .nav-tabs-justified > .active > a:hover, .vue-tabs .nav-tabs.nav-justified > .active > a:hover,
  .vue-tabs .nav-tabs-justified > .active > a:focus, .vue-tabs .nav-tabs.nav-justified > .active > a:focus {
    border: 1px solid #ddd; }
  @media (min-width: 768px) {
    .vue-tabs .nav-tabs-justified > li > a, .vue-tabs .nav-tabs.nav-justified > li > a {
      border-bottom: 1px solid #ddd;
      border-radius: 4px 4px 0 0; }
    .vue-tabs .nav-tabs-justified > .active > a, .vue-tabs .nav-tabs.nav-justified > .active > a,
    .vue-tabs .nav-tabs-justified > .active > a:hover, .vue-tabs .nav-tabs.nav-justified > .active > a:hover,
    .vue-tabs .nav-tabs-justified > .active > a:focus, .vue-tabs .nav-tabs.nav-justified > .active > a:focus {
      border-bottom-color: #fff; } }

.vue-tabs .tab-content > .tab-pane {
  display: none; }

.vue-tabs .tab-content > .active {
  display: block; }

.vue-tabs section[aria-hidden="true"] {
  display: none; }
