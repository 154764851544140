.action-header {
  position: absolute;
  top: 55px;
  right: 0;
  left: 100px;
  width: 100%;
  width: calc(100% - 100px);
  height: 50px;
  display: block;
  z-index: 100;
  background: transparent;
  padding: 0;

  .col-action {
    width: 13%;
    background: transparent;
    display: block;
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 21px;
    text-align: center;

/*    .btn-back-small {
      background: #1e272e;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 30px;
      border-color: #1e272e;
      color: #ff5722;
      -webkit-transition: .1s cubic-bezier(.785,.135,.15,.86);
      -o-transition: .1s cubic-bezier(.785,.135,.15,.86);
      transition: all .1s cubic-bezier(.785,.135,.15,.86) 0s;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-transform: uppercase;
      cursor: pointer;
      &:hover {
        background: #ff5722;
        border-color: #ff5722;
        color: #ffffff;
      }
    }*/

    .btn-back-small {
      font-size: 18px;
      border: none !important;
      background: transparent !important;
      padding: 0.25rem 0.5rem;
      border-radius: 20px;
      color: #ff5722;
      font-family: 'Titillium Web', sans-serif;
      font-weight: 600;
      -webkit-transition: .2s cubic-bezier(.785,.135,.15,.86);
      -o-transition: .2s cubic-bezier(.785,.135,.15,.86);
      transition: all .2s cubic-bezier(.785,.135,.15,.86) 0s;

      svg {
        margin-right: 5px;
      }

      &:hover, &:focus {
        color: #ff7a09;
        background: transparent;
      }

    }

  }

  .col-content {
    width: 87%;
    background: #eef1f7;
    display: block;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;

    &.col-content-new {
      background: #e2ffd1;
    }

    .col-content-info {
        padding: 10px 30px 10px 42px;
        font-size: 24px;
        margin: 0;
        font-family: Roboto Condensed,sans-serif;

      span.pre-title {
        color: #a2afbd;
        font-size: 16px;
        font-weight: 300;
        font-family: Roboto Condensed,sans-serif;
      }

    }

  }

}
