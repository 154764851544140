.card-body table tbody tr td.actions .btn-primary {
  color: #a776b9;
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  &:hover {
    border-color: #a776b9;
    background-color: #a776b9;
    color: #f5f5f5;
  }
}

.app-header {
  .dropdown-menu {
    padding-bottom: 5px;
  }
  .dropdown-item {
    min-width: unset;
  }
}


.ck-editor__editable_inline {
  min-height: 150px;
  width: 100%;
}

.item-name-holder {
  width: 100%;
  display: block;
}

a.item-name-active {
  width: 100%;
  display: block;
  color: #7a7cbc !important;
}

.select-box-with-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 6px;
  border-radius: 2px;
  margin-right: 0;
  //margin-right: 2rem;
  padding: 0.355rem 0.75rem 0.385rem 0.75rem;
  height: 2.375rem;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #e7ebf1;
  line-height: 1.5;
}

.pop-rec {
  letter-spacing: 1px;
}

.pop-big {
  color: #529f03;
  font-weight: bold;
}

.pop-medium {
  color: #56ab00;
}

.pop-stand {
  color: #1c305e;
}

.pop-small {
  color: #505050;
}

.pop-none {
  color: #fba9a9;
}

