.translation-value{
  border-bottom: 1px dashed #a776b9;
  cursor: pointer;
  &.translation-value-missing {
    border-bottom: 1px dashed #ff0000;
    color: #ff0000;
  }
}

.t-data-status {

  div {
    text-align: center;
  }

  .t-status {
    font-size: 17px;
    &.t-status-ok {
      color: #7a7cbd;
    }
    &.t-status-changed {
      color: #ff950d;
    }
    &.t-status-missing {
      color: #ff0000;
    }
  }
}

tbody {
  .data-key {
    color: #888888;
  }
}
