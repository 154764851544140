.file-content-wrap {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  overflow-x: hidden;
  padding-left: 0;
  padding-right: 0;
  overflow-y: hidden;
  min-height: 640px;

  &.file-content-wrap_cover {
    min-height: 280px;
  }

  .file-content {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    bottom: 0;
    transition: 0.3s all ease;
    display: flex;
    .files-container {
      flex: 0 1 100%;
      transition: 150ms all ease;
      position: relative;
      scroll-behavior: smooth;
      &.is-fileinfo-visible {
        .file-grid-wrapper {
          .file-list {
            &.grid {
              display: grid;
              grid-template-columns: repeat(auto-fill, 180px);
              justify-content: space-evenly;
              .file-wrapper {
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                position: relative;
                text-align: center;
                display: inline-block;
                vertical-align: text-top;
                margin-bottom: 5px;
                width: 100%;
                .file-item {
                  border-radius: 8px;
                  background: #f4f5f6;
                  border: 2px dashed transparent;
                  width: 200px;
                  margin: 0 auto;
                  cursor: pointer;
                  position: relative;
                  padding: 15px 0;
                  .icon-item {
                    text-align: center;
                    position: relative;
                    height: 200px;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    .image {
                      max-width: 95%;
                      -o-object-fit: cover;
                      object-fit: cover;
                      -webkit-user-select: none;
                      -moz-user-select: none;
                      -ms-user-select: none;
                      user-select: none;
                      height: 200px;
                      border-radius: 5px;
                      margin: 0 auto;
                      pointer-events: none;
                    }
                  }
                  .remove-photo {
                    margin-bottom: 10px;
                    &:hover {
                      color: red;
                    }
                  }
                }
              }
            }
          }
        }
      }

    }
  }

}

.file-content-wrap-full {
  width: 100%;
  .file-content {
    .files-container {
      .file-grid-wrapper {
        .file-list {
          &.grid {
            grid-template-columns: repeat(auto-fill, 350px) !important;
            .file-wrapper {
              .file-item {
                width: 340px !important;
                .icon-item {
                  height: 380px !important;
                  .image {
                    width: 98%;
                    height: 400px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

