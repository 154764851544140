.group-new-btn-wrap {
  position: relative;
  width: 120px;
  .btn {
    position: absolute;
    top: -25px;
    right: -65px;
    display: inline-block;
  }
}

.group-lang-seo {
  .card-header {
    .card-title {
      width: 100%;
    }
  }
}

.option-new-btn-wrap {
  position: relative;
  width: 120px;
  .btn {
    position: absolute;
    top: -20px;
    right: -65px;
    display: inline-block;
  }
}

.partition-form {
  .button-set {
    text-align: center;
    margin-top: 105px;
  }
}

.card-body {
  .group {
    .group-title {
      display: inline-block;
    }
  }
}

.group-action-wrap {
  display: inline-block;
  text-align: right;
  float: right;
  .group-action {
    z-index: 100;
    cursor: pointer;
    margin-left: 0;
    margin-right: 3px;
    height: 26px;
    width: 34px;
    display: block;
    float: left;
    text-align: center;
    &:hover {
      opacity: 0.7;
    }
  }
}

.tab-content {
  .attribute-options {
    .card {
      .card-body {
        padding: 0.1rem 0 0 1.5rem;
        .group {
          position: relative;
          font-family: 'Titillium Web', sans-serif;
          font-size: 16px;
          font-weight: 500;
          background: #ffffff;
          border: 1px solid rgba(168, 118, 185, 0.25);
          border-radius: 3px;
          color: #495057;
          padding: 9px 10px 10px 15px;
          .title {
            margin-bottom: 0;
            padding-right: 100px;
            .svg-inline--fa {
              margin-right: 7px;
            }
          }
          .group-action-wrap {
            position: absolute;
            &.group-action-edit {
              top: 0;
              right: 45px;
              width: 24px;
              text-align: center;
              &:hover {
                color: #7a7cbd;
                cursor: pointer;
              }
            }
            &.group-action-delete {
              top: 0;
              right: 15px;
              width: 24px;
              text-align: center;
              &:hover {
                color: red;
                cursor: pointer;
              }
            }
          }
        }
      }

    }

    &.attribute-list {
      .card {
        .card-body {
          .group {
            .title {
              width: 20%;
              display: inline-block;
              font-weight: 600;
            }
            .group-value {
              display: inline-block;
              width: 70%;
            }
          }
        }
      }
    }

  }

}

.ck.ck-editor {
  width: 100%;
}

.attribute-list {
  padding-right: 12px;
  .attribute-item {
    border-radius: 10px !important;
    box-shadow: 0px 6px 24px 2px rgba(54, 59, 100, 0.08) !important;
    border: none !important;
    .item-name {
      position: relative;
      font-weight: 600;
      color: #5e5e5e;
      padding-left: 23px;
      display: block;
      .svg-inline--fa {
        margin-right: 6px;
        color: #cacaca;
        position: absolute;
        top: 4px;
        left: -2px;
      }
      &.item-type {
        padding-left: 0;
        font-weight: 400;
      }
      &.item-data {
        padding-left: 0;
        font-weight: 400;
        p {
          margin-bottom: 0;
        }
      }
    }
    &:hover {
      .item-name {
        color: #7a7cbd;
        .svg-inline--fa {
          color: #7a7cbd;
        }
      }
      .item-data {
        color: #5e5e5e;
      }
    }
    &.attribute-item-header {
      margin-bottom: 10px;
      background: -webkit-gradient(linear, left top, right top, from(#7a7cbd), to(#a976b9)) !important;
      background: linear-gradient(
          90deg
        , #7a7cbd, #a976b9) !important;
      .item-name {
        font-weight: 600;
        color: #FFFFFF;
        padding-left: 24px;
        &:hover {
          color: #FFFFFF;
          .svg-inline--fa {
            color: #FFFFFF;
          }
        }

        &.item-type {
          padding-left: 0;
        }

        &.item-data {
          padding-left: 0
        }

      }
    }
    &.attribute-item-empty {
      color: #fb5b32 !important;
      padding: 9px 38px 13px 38px !important;
      text-align: center;
      font-weight: 600 !important;
    }
  }

  .item-show-more-btn {
    border: 1px solid #eeeeee;
    background: #eeeeee;
    font-weight: 600;
    color: #7a7cbd;
    font-size: 11px;
    border-radius: 3px;
  }
}

.attribute-type-block {
  font-family: 'Titillium Web', sans-serif;
  font-size: 15px;
  font-weight: 600;
  padding-top: calc(0.355rem + 1px);
  padding-bottom: calc(0.385rem + 1px);
  color: #6f73ad;
}

.col-form-label-value {
  color: #7f7f7f;
  text-align: left !important;
}

.data-not-filled {
  color: #ff5722;
  font-weight: 400;
  font-size: 13px;
}

.block-select-title {
  font-weight: 600;
  font-size: 14px;
  color: #6f73adb3;
  border-bottom: 1px dashed #f2f2fb;
  padding: 10px 12px 5px 12px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.service-items-wrap {
  .row-service-item {
    border-top: 1px solid #e1e5fa;
    .col-service-item-name {
      border-left: 1px solid #e1e5fa;
      border-right: 1px solid #e1e5fa;
      padding: 11px 29px 12px 29px;
      font-family: "Roboto Slab", sans-serif;
      font-weight: 600;
      color: #1d1921;
      text-align: right;
      line-height: 24px;
      font-size: 16px;
      background: #fdfdff;
    }
    .col-service-item-value {
      border-right: 1px solid #e1e5fa;
      padding: 11px 29px 12px 29px;
      font-family: "Roboto Slab", sans-serif;
      font-weight: 400;
      color: #1d1921;
      text-align: left;
      line-height: 24px;
      font-size: 16px;
    }
    &:last-child {
      border-bottom: 1px solid #e1e5fa;
    }
    &.row-service-item-history {
      margin-bottom: 15px;
    }
  }

  .row-service-item-history {
    .col-service-item-name {
      border-left: 1px solid #e1e5fa;
      border-right: 1px solid #e1e5fa;
      padding: 11px 29px 12px 29px;
      font-family: "Roboto Slab", sans-serif;
      font-weight: 600;
      color: #404040;
      text-align: right;
      line-height: 24px;
      font-size: 15px;
      background: #fdfdff;
    }
    .col-service-item-value {
      .row-history-user {
        margin-bottom: 10px;
        .col-history-name {
          font-weight: 600;
          color: #404040;
          font-size: 15px;
        }
      }
      .row-history-status {
        margin-bottom: 5px;
        .col-history-label {
          text-align: right;
          color: #404040;
          font-size: 15px;
        }
        .col-history-value {
          text-align: left;
          color: #404040;
          font-size: 15px;
        }
      }
      .row-history-reference {
        margin-bottom: 5px;
        .col-reference-label {
          text-align: right;
          color: #404040;
          font-size: 15px;
        }
        .col-reference-value {
          text-align: left;
          color: #404040;
          font-size: 15px;
          font-weight: 600;
          letter-spacing: 0.05em;
        }
      }
      .row-history-send-user {
        margin-bottom: 5px;
        .col-send-label {
          text-align: right;
          color: #404040;
          font-size: 15px;
        }
        .col-send-value {
          text-align: left;
          color: #404040;
          font-size: 15px;
          .email-send-status {
            background: #FFFFFF;
            border: 1px solid #989898;
            color: #787878;
            padding: .3em .7em .33em .7em;
          }
          .vline-divider {
            color: #c7c7c7;
            padding-right: 5px;
            padding-left: 5px;
          }
          .user-email-list {

          }
        }
      }
      .row-history-comment {
        margin-top: 15px;
        margin-bottom: 5px;
        .col-comment-label {
          text-align: right;
          color: #404040;
          font-size: 15px;
        }
        .col-comment-value {
          text-align: left;
          color: #404040;
          font-size: 15px;
        }
      }
    }
  }
}


.badge-draft {
  background: #989898;
  color: #FFFFFF;
}

.status-divider {
  color: #404040;
  padding: .3em .7em .4em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  display: inline-block;
}

.badge-check {
  background: #45aaf2;
  color: #FFFFFF;
}

.badge-verification {
  background: #45aaf2;
  color: #FFFFFF;
}

.badge-check-verification {
  background: #45aaf2;
  color: #FFFFFF;
}

.badge-register {
  background: #f1c40f;
  color: #FFFFFF;
}

.badge-canceled {
  background: #d9480b;
  color: #FFFFFF;
}

.badge-suspended {
  background: #d9480b;
  color: #FFFFFF;
}

.badge-reviewed {
  background: #3f51b5;
  color: #FFFFFF;
}

.badge-revise {
  background: #f1c40f;
  color: #FFFFFF;
}

.badge-completed {
  background: #5eba00;
  color: #FFFFFF;
}

.badge-active {
  background: #5eba00;
  color: #FFFFFF;
}

.badge-correct {
  background: #5eba00;
  color: #FFFFFF;
}

.form-row-info {
  .col-form-label {

  }

  .col-form-label-value {
    color: #404040;
  }
}

.form-row-no-bm{
  margin-bottom: 0;
}

.card-body table tbody tr td.data-full-name{
  font-size: 16px;
  color: #272738;
}

.data-content-wrap {

  .new-data-wrap {
    padding: 0 20px 20px 20px;
  }

  .old-data-wrap {
    padding: 0 20px 20px 20px;
  }

  .data-row-title {
    border-left: 1px solid #e1e5fa;
    border-right: 1px solid #e1e5fa;
    border-top: 1px solid #e1e5fa;
    border-bottom: 1px solid #e1e5fa;
    padding: 11px 29px 12px;
    font-family: Roboto Slab,sans-serif;
    font-weight: 600;
    color: #7a7cbd;
    text-align: left;
    line-height: 24px;
    font-size: 15px;
    background: #fdfdff;
    margin-right: 10px;
  }

  .data-row-label {
       font-weight: 700;
    text-align: right;
    border-left: 1px solid #e1e5fa;
    border-right: 1px solid #e1e5fa;
    border-bottom: 1px solid #e1e5fa;
    padding: 5px 10px 5px 10px;
  }
  .data-row-data {
    text-align: left;
    border-left: 1px solid #e1e5fa;
    border-right: 1px solid #e1e5fa;
    border-bottom: 1px solid #e1e5fa;
    padding: 5px 10px 5px 10px;
  }
}
