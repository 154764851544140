.list-page {
  height: 100%;
}



.data-sidebar {
  z-index: 5;
  /* -webkit-box-shadow: 0 0 28px 0 rgba(60,68,177,.08);
   box-shadow: 0 0 28px 0 rgba(60,68,177,.08);*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all .5s cubic-bezier(.685,.0473,.346,1);
  transition: all .5s cubic-bezier(.685,.0473,.346,1);
  border-right: 1px solid #e3e8ef !important;
  background-color: #fff !important;


  .side-block {
    width: 100%;
    padding: 9px 30px 10px 30px;

    .data-sidebar-title {
      width: 100%;
      text-transform: uppercase;
      color: #7a7cbd;
      font-size: .79167rem;
      padding: 1rem 0;
      font-weight: 700;
    }

    .data-sidebar-data {
      width: 100%;

      .sidebar-title {
        width: 100%;
        text-transform: uppercase;
        color: #7a7cbd;
        font-size: 15px;
        padding: 1rem 0;
        font-weight: 600;
        font-family: 'Titillium Web', sans-serif;
      }

      .sidebar-choices {
        .value {
          margin-bottom: 5px;
          button {
            width: 100%;
            border: none !important;
            background: transparent;
            font-family: 'Titillium Web', sans-serif;
            font-size: 14px;
            text-align: left;
            padding: 2px 15px 3px 15px;
            border-radius: 4px;
            &.active {
              background: rgb(122, 124, 189);
              color: #ffffff;
            }
          }
        }
      }
    }
  }

}

.list-settings {
  height: 100vh;
  background: #eff3f6;

  .data-sidebar {
    padding-right: 0;
    .side-block {
      padding: 0;
      .data-sidebar-data {
        .sidebar-title {
          display: none;
        }

        .sidebar-choices {
          .value {
            -ms-flex-align: center;
            -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            padding: 15px 12px 15px 22px;
            border-bottom: 1px solid #e3e8ef;
            border-left: 0 solid transparent;
            -webkit-transition: border-left .15s;
            transition: border-left .15s;
            cursor: pointer;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            margin-bottom: 0;
            .opt-icon {
              -ms-flex-align: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              margin-right: 15px;
              padding: 4px;
              width: 34px;
              height: 34px;
              border-radius: 2px;
              display: flex;
              -webkit-box-align: center;
              align-items: center;
            }
            .opt-content {
              -webkit-box-flex: 1;
              -ms-flex: 1;
              flex: 1;
              .opt-title {
                margin: 0;
                color: #1c293b;
                font-weight: 800;
                font-size: 14.5px;
                text-decoration: none !important;
              }
              .opt-desc {
                margin: 0;
                margin-top: 3px;
                color: #75869c;
                font-size: 13px;
                line-height: 15px;
                text-decoration: none !important;
              }
            }
            &:hover {
              text-decoration: none !important;
            }
            &.router-link-exact-active {
              background: #fafbfc;
            }
          }
        }
      }
    }
  }

}
