.draggable{
  background: rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px;
  cursor: move;
  margin-bottom: 10px;
}
.draggable .title{
  margin-bottom: 15px;
  display: block;
}

.sortable-ghost{
  background: rgba(0, 0, 0, 0.2);
  display: block;
}

.sortable-chosen {
  background: rgba(0, 0, 0, 0.3);
  display: block;
}
