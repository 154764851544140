.badge {
  display: inline-block;
  padding: .3em .7em .4em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.badge-success {
  color: #fff;
  background-color: #5cb85c;
}

.badge-1 {
  color: #fff;
  background-color: #5cb85c;
}

.badge-vip {
  color: #fff;
  background-color: #7f2071;
}

.badge-has-plan {
  color: #fff;
  background-color: #7a7cbd;
}

.badge-gold {
  color: #fff;
  background-color: #938f00;
}

.badge-danger {
  color: #fff;
  background-color: #ff4800;
}

.badge-grey {
  margin-right: 3px;
  background-color: #f1f1f1;
}

.badge-2 {
  color: #fff;
  background-color: #ff4800;
}

.badge-unverified {
  color: #fff;
  background-color: #4999de;
}

.badge-pad {
  margin-right: 5px;
  background: #303f55;
  color: #ffffff;
}

.badge-with-link {
  a{
    color: #ffffff;
    &:hover {
      text-decoration: none;
    }
  }
  &:hover {
    background: #7a7cbd;
  }
}

.badge-no-link {
  span{
    color: #ffffff;
    &:hover {
      text-decoration: none;

    }
  }
  &:hover {
    background: #7a7cbd;
    cursor: text;
  }
}

.badge-with-contact {
  display: inline-block;
  padding: .4em .4em .3em .4em;
  font-size: 80%;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.status-banned {
  color: #ff4800;
}

.status-live {
  color: #e3e3e3;
}

.dash-tr-link {
  color: #7a7cbd;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  &:hover {
    text-decoration: none;
    color: #c39ad2;
  }
}

.dash-tr-text {
  color: #495057;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.inner-tr-link {
  color: #7a7cbd;
  font-size: 15px;
  font-weight: 600;
  &:hover {
    text-decoration: none;
    color: #c39ad2;
  }
}

.inner-tr-text {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}

.data-name-wide {
  min-width: 300px;
}

.data-name-strong {
  font-weight: 600;
  color: #006bff;
}

.pre-list-label {
  color: #000000;
  padding-bottom: 5px;
  margin-top: -2px;
  &.inactive {
    color: #8c8c8c;
  }
}

.inactive {
  .data-name-strong {
    font-weight: 400;
    color: #8c8c8c;
  }
}

.data-ids {
  font-size: 90%;
  div {
    font-size: 90%;
    .inactive {
      color: #8c8c8c;
    }
  }
}

.data-name {
  div {
    .inactive {
      color: #8c8c8c;
    }
  }
}

.select-plan-label {
  font-weight: bold;
  margin-bottom: 20px;
}

.plan-list {
  input {
  }
  label {
    padding-left: 10px;
    font-weight: bold;
  }
}

.plan-until-label {
  font-size: 11px;
  margin-top: 3px;
}

.coin-amount {
  font-weight: 700;
  font-size: 16px;
  text-align: right;
}

.coin-price {
  font-weight: 600;
  font-size: 16px;
}

.coin-price-old {
  font-weight: 600;
  font-size: 16px;
  color: #5cb85c;
}

.coins-in-list {
  .fa-coins {
    color: #c3c3c3;
    margin-left: 10px;
  }
  .balance-in {
    font-size: 14px;
    font-weight: 600;
    .balance-pos {
      color: #5eba00;
    }
    .balance-neg {
      color: #c3c3c3;
    }
  }
}

.amount-plus {
  font-size: 15px;
  color: #5eba00;
  font-weight: 600;
}

.amount-before, .amount-after  {
  font-size: 14px;
  color: #434343;
  font-weight: 500;
}
