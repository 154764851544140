.dashboard-page {
  padding-top: 20px;
}

.dashboard-table {
  margin-top: -10px;
}

.main {
  .container-fluid {
    .d-header-row {
      .card-title {
        color: #7a7cbd;
        font-family: 'Titillium Web', sans-serif;
        font-size: 16px;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 2.5rem;
        text-transform: uppercase;
      }
    }
    .d-body-row {
      .d-card {
        margin-bottom: 24px;
        -webkit-box-shadow: 0 0 1rem rgba(18, 38, 63, 0.15) !important;
        box-shadow: 0 0 1rem rgba(18, 38, 63, 0.15) !important;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0 solid #f6f6f6;
        border-radius: .25rem;
        .card-body {
          padding: 0.9rem 1.5rem 0.9rem 1.5rem;
        }
        &.d-card-main {
        }
        .media {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          .media-body {
            -webkit-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            .text-black {
              color: #000 !important;
              font-weight: 600;
              &.fw-medium {
                color: #767676 !important;
              }
            }

            .fs-28 {
              font-size: 28px !important;
              line-height: 1.4;
            }
          }
        }
        .mini-stat-icon {
          overflow: hidden;
          position: absolute;
          right: 20px;
          bottom: 22px;
          .icon-title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            color: #000;
            font-weight: 500;
            height: 100%;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 100%;
            .svg-inline--fa {
              font-size: 24px!important;
            }
          }
        }
      }
    }
  }
}

.card-adj >
.card-body {
  padding-bottom: 0 !important;
}

.icon-red {
  color: red !important;
}

.icon-light-green {
  color: #6bce6b !important;
}

.icon-yellow {
  color: red !important;
}

.icon-orange {
  color: orange !important;
}

.icon-light-blue {
  color: #57a6ff !important;
}

.icon-violet {
  color: #7a7cbd !important;
}

.icon-grey {
  color: #acacac !important;
}

.main {
  .container-fluid {
    .d-body-row {
      .d-card .media {
        .media-body {
          .d-text-violet {
            color: #7a7cbd !important;
          }
          .d-text-red {
            color: red !important;
          }
          .d-text-orange {
            color: orange !important;
          }
          .d-text-light-blue {
            color: #57a6ff !important;
          }
          .d-text-grey {
            color: #acacac !important;
          }
        }
      }
    }
  }
}

.btn-all-dashboard {
  line-height: 1;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 40px 10px 40px;
  cursor: pointer;
  color: #fff;
  background-color: #a776b9;
  border-color: #a776b9;
  text-align: center;
  border-radius: 3px;
  .svg-inline--fa {
    margin-left: 8px;
    padding-top: 3px;
  }
  &:hover {
    background-color: #864c9a;
    border-color: #864c9a;
  }
}


