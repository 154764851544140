.dropdown-menu {
  -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.10), 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.12), 0 -3px 6px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.10), 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 5px rgba(0, 0, 0, 0.12), 0 -3px 6px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid rgba(0, 0, 0, 0.01);
  border-radius: 4px;
  padding: 5px;
  background-color: #fff;
  background-clip: padding-box;
  left: -20px !important;

  .dropdown-item {
    padding: 7px 10px 7px 10px;
    span.icon-holder {
      display: inline-block;
      min-width: 30px;
      text-align: center;
      color: #a776b9;
      &.icon-danger {
        color: red;
      }
    }
    span.label-holder {
      font-size: 14px;
      font-weight: 600;
      &.label-danger {
        color: red;
      }
      &.label-delete {}
    }
  }
}

.action-btn-dropdown {
  .btn-sm {
    color: #fff;
    border-color: #f5f5f5;
    border-radius: 3px;
    background: #f5f5f5;
    &:after {
      content: '\2807';
      color: #a776b9;
      border: none;
      vertical-align: 0;
    }
    &:hover {
      color: #ffffff;
      background-color: #a776b9;
      border-color: #a776b9;
      &:after {
        color: #ffffff;
      }
    }
  }

}

.dropdown-toggle:empty::after {
  margin-left: 4px;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #ffffff;
  background-color: #a776b9;
  border-color: #a776b9;
  &:after {
    color: #ffffff;
  }
}
