html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  background: #ffffff;
}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none !important;
  box-shadow: none !important;
}

*:focus {
  outline: none !important;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

.main .container-fluid {
  padding: 0;
  margin-top: 0;

  .card {
    border: none;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
  }
}

.app-header {
  border-bottom: none !important;
}

.custom-multiselect {
  .dropdown-menu {
    width: 100%;
  }
}

.header-card-title {
  border-bottom: none;
  margin-bottom: 30px;
  background: none;
  padding: 0.5rem 1.5rem 0 1.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: -25px;
  margin-right: -25px;
  font-family: Roboto Condensed, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.body-container {
  padding-top: 25px;
  padding-left: 10px;
}

.order-container {
  padding: 0;

  .order-container-holder {
    padding: 30px 60px 30px 0px;
    width: 100%;

    .order-container {
      .order-item {
        padding: 5px 5px 5px 5px;
        border-bottom: 1px solid #eeeeee;
        width: 100%;

        .order-item-img {
          padding: 3px;
          width: 35%;
          display: inline-block;

          img {
            width: 120px;
            height: 120px;
            border: 2px solid #e3e3e3;
            border-radius: 3px;
            padding: 3px;
          }
        }

        .order-item-info {
          width: 45%;
          display: inline-block;
        }

        .order-item-action {
          width: 15%;
          display: inline-block;
          text-align: right;

          span {
            font-family: Roboto;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.tab-content {
  .card {
    margin-bottom: 0;

    .card-body{
      padding: 2rem 1.5rem 0 1.5rem;
    }

    textarea {
      min-height: 64px;
    }

    .col-form-label {
      text-align: right;
      font-family: 'Titillium Web', sans-serif;
      font-size: 16px;
      font-weight: 600;
      padding-top: calc(0.355rem + 1px);
      padding-bottom: calc(0.385rem + 1px);
    }

    .form-control {
      height: 2.375rem;
      padding: 0.355rem 0.75rem 0.385rem 0.75rem;
      font-size: 0.9375rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fafafa;
      background-clip: padding-box;
      border: 1px solid #e7ebf1;
      border-radius: 2px;
      -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      box-shadow: none !important;
      -webkit-appearance: none !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      font-family: 'Titillium Web', sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

.custom-switch-input:checked ~ .custom-switch-indicator {
  background: #7a7cbd;
}

.card-header {
  padding: 0.5rem 1.5rem 0.1rem 1.5rem;
  min-height: 2.5rem;
  .card-title {
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #868e96;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    min-height: 2.5rem;
    text-transform: uppercase;
  }
}

.list-page {
  .card {
    .card-header {
      border-bottom: none;
      padding: 1.4rem 1.5rem 0.2rem 1.5rem;

      .card-title {
        min-height: 0;
        color: #7a7cbd;
      }

      .card-options {
        margin-right: 0;
      }
    }

    .card-body {
      padding: 0.9rem 1.5rem 1.5rem 1.5rem;
      font-family: 'Titillium Web', sans-serif;
    }
  }
}

.custom-select {
  height: 2rem;
  padding: 0.305rem 1.5rem 0.305rem 0.75rem;
  line-height: 1.4;
  color: #495057;
  vertical-align: middle;
  background-size: 8px 10px;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;
}

.search-holder {
  &.form-inline {
    label {
      font-weight: 600;
    }

    .form-control {
      height: 2rem;
      padding: 0.305rem 0.55rem 0.305rem 0.55rem;
      font-size: 0.9375rem;
      line-height: 1.4;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 40, 100, 0.12);
      border-radius: 3px;
      -webkit-transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
        -webkit-box-shadow 0.15s ease-in-out;
    }
  }
}

.invalid-feedback {
  font-size: 14px;
  padding-left: 0.85rem;
  color: #f44336;
  font-family: 'Titillium Web', sans-serif;
}

.form-text {
  /*padding-left: 0.85rem;*/
  padding-left: 0;
}

.form-row {
  .url-link {
    padding: 0.355rem 0.75rem 0.385rem 0.75rem;
    line-height: 1.5;

    a {
      text-decoration: none;
      color: #a876b9;
      font-family: 'Titillium Web', sans-serif;
      font-size: 16px;
      font-weight: 400;

      &:hover {
        color: #6a6b9c;
      }
    }
  }

  .url-link-new {
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #011a2a;
  }
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #7a7cbd;
  border-color: #7a7cbd;
}

.vc-chrome {
  position: absolute;
  top: 35px;
  right: 0;
  z-index: 9;
}

.current-color {
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #000;
  cursor: pointer;
}

.color-preview {
  width: 30px;
  height: 30px;
  display: block;
  border-radius: 3px;
  margin: -4px auto 0 auto;
}

.variants-container {
  margin-left: -35px;
  margin-bottom: 80px;

  .delete-variant {
    text-align: right;
  }

  .variants-nav-holder {
    padding-right: 0;

    .variants-nav {
      padding: 15px 0px 15px 0px;
      cursor: pointer;

      .variant-nav {
        border: 3px solid #ebeef3;
        padding: 10px 20px 12px 20px;
        margin-bottom: 15px;
        background: #ebeef3;
        color: #6a6a6a;
        font-family: 'Titillium Web', sans-serif;
        font-size: 18px;
        font-weight: 400;

        .variant-sku {
          font-size: 16px;
          font-weight: 700;
          display: block;
          width: 100%;
          margin-bottom: -5px;
        }

        .variant-name {
          font-size: 20px;
          font-weight: 700;
          display: block;
          width: 100%;
        }

        .variant-description {
          font-size: 14px;
          font-weight: 400;
          display: block;
          width: 100%;
          margin-top: 2px;
          margin-bottom: 2px;
          p {
            margin-bottom: 2px;
          }
        }

        .variant-price {
          font-size: 16px;
          font-weight: 400;
          display: block;
          width: 100%;
        }

        &.active {
          background: #ffffff;
          color: #000000;
          border-right: 3px solid #ffffff;
          position: relative;
          z-index: 20;
          -webkit-box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.52);
          -moz-box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.52);
          box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.52);
        }
      }
    }
  }

  .variant-data-holder {
    position: relative;
    z-index: 10;
    -webkit-box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.52);
    box-shadow: -10px 0px 10px -10px rgba(0, 0, 0, 0.52);
    padding-left: 0;
    margin-left: -3px;
    margin-top: 15px;
    border: 3px solid #ebeef3;

    .variant-data {
      padding: 0px 10px 20px 0px;

      .variant-data-container {
        padding: 25px 0 20px 20px;
      }
    }
  }
}

.variant-info-code {
  padding: 0.355rem 0.75rem 0.385rem 0.75rem;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 700;
  display: block;
  width: 100%;
  margin-bottom: -5px;
  font-family: 'Titillium Web', sans-serif;
}

.vue-dropzone {
  border: 2px dashed rgb(122, 124, 189);
  font-family: 'Titillium Web', sans-serif;
  letter-spacing: .2px;
  color: #777;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
}

.dropzon-container {
  position: relative;
}

.dropzone-custom-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.dropzone-custom-title {
  margin-top: 0;
  color: rgb(122, 124, 189);
  font-family: 'Titillium Web', sans-serif;
}

.subtitle {
  color: #314b5f;
  font-family: 'Titillium Web', sans-serif;
}

.custom-switch {
  padding-left: 0;
  margin-left: -3px;
}

.badge-custom {
  padding: 0.36em 0.6em;
  font-size: 11px;
  font-weight: 300;
  font-family: 'Roboto', sans-serif;
}

.input-group-prepend,.input-group-append {
  &.input-group-prepend-title {
       .input-group-text {
         .svg-inline--fa {
           margin-top: -1px;
         }
         span {
           font-size: 14px;
           font-weight: 400;
           font-family: Roboto Condensed, sans-serif;
           text-transform: uppercase;
         }
       }
      &.prepend-lang-required {
        .input-group-text {
          background: #364760;
          color: #ffffff;
        }
      }
     }
  &.input-group-prepend-position {
    .input-group-text {
      .svg-inline--fa {
        margin-top: -1px;
      }
    }
  }
  &.input-group-append-title {
    .input-group-text {
      .svg-inline--fa {
        margin-top: -3px;
      }
    }
  }
  .input-group-text {
    width: 40px;
    .svg-inline--fa {
      color: #c3c3c3;
    }
  }
}


.invalid-feedback {
  display: block;
}

.association-img {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
   img {
    max-width: 100%;
    height: auto;
     max-height: 55px;
  }
}

.card-body table.table-core tr td {
  border-top: none;
  padding: 1rem 0.75rem 1.2rem 0.75rem;
}

.card-title-core {
  margin-bottom: 15px !important;
}

.main .container-fluid .card.card-order {
  margin-bottom: 0;

  .card-body {
    padding: 1.5rem 1.5rem 0 1.5rem;
  }
}

.vue-dialog {
  .card {
    .form-control {
      &.is-invalid {
/*      -webkit-box-shadow:inset 0px 0px 0px 1px #f00  !important;
        -moz-box-shadow:inset 0px 0px 0px 1px #f00 !important;
        box-shadow:inset 0px 0px 0px 1px #f00 !important;*/
      }
    }
  }
}

.input-group-text {
  padding: 0.4210rem 0.75rem 0.320rem 0.75rem;
}

.group-lang {
  &.group-lang-seo {
    /*margin-bottom: 30px;
    padding-bottom: 11px;*/
  }
}

.app-header .navbar-brand {
  img {
    margin-top: -2px;
  }
}

.data-content-col{
  p {
    margin-bottom: 6px;
    max-width: 400px;
  }
}

.g-search-wrap {
  width: 100%;
  .input-group {
    label {
      width: 70%;
      max-width: 70%;
    }
    .input-group-append {
      height: 38px;
    }
  }
}


.vue-map-container {
  border: 2px solid rgb(122, 124, 189);
  border-radius: 3px;
}

.btn-back-small-categories {
  font-size: 16px;
  border: none !important;
  background: transparent !important;
  padding: 0 10px 0 0;
  border-radius: 0;
  color: #ff5722 !important;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 600;
  -webkit-transition: .2s cubic-bezier(.785,.135,.15,.86);
  -o-transition: .2s cubic-bezier(.785,.135,.15,.86);
  transition: all .2s cubic-bezier(.785,.135,.15,.86) 0s;
  line-height: 1.2;
  margin-right: 8px;

  border-right: 2px solid #d0d0d0 !important;

  svg {
    margin-right: 5px;
  }

  &:hover, &:focus {
    color: #ff7a09 !important;
    background: transparent;
  }

}

.form-row .data-identifier-block {
  padding-left: 0;
  padding-right: 0.75rem;
  line-height: 1.6;
  padding-top: calc(0.355rem + 1px);
  padding-bottom: calc(0.385rem + 1px);

  span {
    text-decoration: none;
    color: #a876b9;
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    font-weight: 500;
    border: 1px dashed #a876b9;
    border-radius: 3px;
    padding: 5px 15px 6px 15px;
  }
}

.form-row .data-identifier-block-trans {
  text-decoration: none;
  color: #a876b9;
  font-family: 'Titillium Web', sans-serif;
  font-size: 16px;
  font-weight: 500;
  border: 1px dashed #a876b9;
  border-radius: 3px;
  padding: 5px 15px 6px 15px;
}

.form-row .data-info-block {
  padding-left: 0;
  padding-right: 0.75rem;
  line-height: 1.6;
  padding-top: calc(0.355rem + 1px);
  padding-bottom: calc(0.385rem + 1px);

  span {
    text-decoration: none;
    color: #a876b9;
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    font-weight: 500;
    padding: 9px 15px 10px 15px;
  }
}


.attribute-groups {
  .group {
    font-family: 'Titillium Web', sans-serif;
    font-size: 18px;
    font-weight: 600;
    background: rgba(168, 118, 185, 0.05);
    border: 1px solid rgba(168, 118, 185, 0.25);
    border-radius: 3px;
    color: #a876b9;

    .fa-arrows-alt-v {
      margin-right: 5px;
      margin-left: 10px;
      color: rgba(168, 118, 185, 0.50);
    }

    .group-items {
      background: #ffffff;
      border: 1px dashed rgba(168, 118, 185, 0.7);
      border-radius: 3px;
      padding: 5px 5px 0 5px;
      min-height: 30px;
      .item {
        border-radius: 3px;
        background: rgba(122, 124, 189, 0.15);
        color: #2c2d4e;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 5px;
        padding: 5px 10px 5px 10px;
        .fa-arrows-alt {
          color: rgba(65, 66, 128, 0.3);
          margin-right: 5px;
        }
        .attribute-type {
          font-weight: 400;
          color: rgba(65, 66, 128, 0.60);
        }
      }
    }
  }
}

.attributes-unassigned {
  .unassigned-wrap {
    background: #ffffff;
    border: 1px dashed rgba(152, 185, 118, 0.7);
    border-radius: 3px;
    padding: 5px 5px 0 5px;
    font-family: 'Titillium Web', sans-serif;
    min-height: 30px;
    .item {
      border-radius: 3px;
      background: rgba(161, 189, 122, 0.10);
      color: #538620;
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 5px;
      padding: 5px 10px 5px 10px;
      .fa-arrows-alt {
        color: rgba(106, 128, 65, 0.3);
        margin-right: 5px;
      }
      .attribute-type {
        font-weight: 400;
        color: rgba(106, 128, 65, 0.60);
      }
    }
  }
}

.adm-btn {
  margin-left: 10px;
}

.btn-warning {
  color: #fff;
  background-color: #a776b9;
  border-color: #a776b9;
  &:hover {
    background-color: #814f92;
    border-color: #814f92;
  }
}

.btn-info {
  color: #fff;
  background-color: #ff5722;
  border-color: #ff5722;
  &:hover {
    background-color: #d9480b;
    border-color: #d9480b;
  }
}

.card-header {
  .card-options {
    .btn-sm {
      padding: 0.25rem 0.5rem 0.32rem 0.5rem;
      font-size: 14px;
      line-height: 1;
      border-radius: 3px;
      font-weight: 600;
      font-family: 'Titillium Web', sans-serif;
    }
  }
}


.btn-view {
  color: #a776b9;
  background-color: #fff;
  border-color: #a776b9;
}

.select-box-with-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 6px;
  border-radius: 2px;
  margin-right: 0;
  //margin-right: 2rem;
  padding: 0.355rem 0.75rem 0.385rem 0.75rem;
  height: 2.375rem;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #e7ebf1;
  line-height: 1.5;
}

.form-control-format {
  max-width: 100px;
  margin-left: 10px!important;
}

.term_max {
  max-width: 100px;
}

.remaining-day {
  width: 100%;
  display: block;
}

.ends-day {
  width: 100%;
  display: block;
}

.plan-record {
  width: 100%;
  display: block;
  border: 2px solid #be9bca;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.plan-label {
  width: 100%;
  display: block;
}

.plan-comment {
  width: 100%;
  display: block;
  font-weight: 500;
}
