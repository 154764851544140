.sidebar-minimized {
  .v-menu-label {
    padding: 8px 10px 15px 10px;
    width: $sidebar-minimized-width;
    text-align: center;
    color: #dee7ed;
    width: 100%;
    font-weight: 400;
    font-size: 18px;
    height: 40px;
    display: block;
    font-family: 'Roboto Condensed', sans-serif;
  }

  &.brand-minimized {

    .sidebar {

      font-family: 'Roboto Condensed', sans-serif;
      font-weight: 400;

      /*      -webkit-box-shadow: 2px 0px 5px 0px #000000;
            box-shadow: 2px 0px 5px 0px #000000;*/

      /*      box-shadow: 3px 0px 4px 0px rgba(14,10,57,0.32);
            -webkit-box-shadow: 3px 0px 4px 0px rgba(14,10,57,0.32);
            -moz-box-shadow: 3px 0px 4px 0px rgba(14,10,57,0.32);*/

      /*      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);*/

      background: #304056;
      //background: #2f373e;

      /*      background: -moz-linear-gradient(270deg, rgba(10,34,68,1) 0%, rgba(5,17,33,1) 100%); !* ff3.6+ *!
            background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(10,34,68,1)), color-stop(100%, rgba(5,17,33,1))); !* safari4+,chrome *!
            background: -webkit-linear-gradient(270deg, rgba(10,34,68,1) 0%, rgba(5,17,33,1) 100%); !* safari5.1+,chrome10+ *!
            background: -o-linear-gradient(270deg, rgba(10,34,68,1) 0%, rgba(5,17,33,1) 100%); !* opera 11.10+ *!
            background: -ms-linear-gradient(270deg, rgba(10,34,68,1) 0%, rgba(5,17,33,1) 100%); !* ie10+ *!
            background: linear-gradient(180deg, rgba(10,34,68,1) 0%, rgba(5,17,33,1) 100%); !* w3c *!
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0a2244', endColorstr='#051121',GradientType=0 ); !* ie6-9 *!*/

      .nav-dropdown.open {
        background: #293649;
      }

      .nav {
        /*        background-image: url('../../img/sidebar-bg-7.jpg');
                background-position: bottom left;
                background-repeat: no-repeat;*/

        .nav-dropdown {
          &:hover {
            background: #0f0c29; /* fallback for old browsers */
            background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29); /* Chrome 10-25, Safari 5.1-6 */
            background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

            /*            .nav-list-title {
                          position: fixed;
                          top: 0;
                          left: 100px;
                        }

                        .nav-dropdown-items {
                          position: fixed;
                          top: 0;
                          left: 100px;
                        }*/

          }

          .nav-dropdown-items {
            background: #222c3c;
          }
        }
      }

      .nav-link {
        padding: 7px 0 4px 0;
        text-decoration: none;
        white-space: normal;

        .nav-icon {
          font-size: 18px;
          margin: 0 0 2px 0;
          color: #dee7ed;
        }
      }

      ul.nav {
        li {
          &.nav-dropdown {
            .nav-list-title {
              padding: 0;
              width: 95px;
              margin-left: 0;
              display: block;
              text-align: center;
              color: #dee7ed;
              /*color: #8d9eb5;*/
              font-family: 'Titillium Web', sans-serif;
              font-size: 13px;
              font-weight: 400;
            }



            &:hover {
              .v-menu-label {
                color: #dee7ed;
              }

              .nav-list-title {
                color: #ffffff;
              }
            }

            &:first-child {
              ul.nav-dropdown-items {
                margin-top: -51px;
              }
            }

            &.nav-sales {
              ul.nav-dropdown-items {
                top: -50px;
              }
            }

            &.nav-content {
              ul.nav-dropdown-items {
                top: -160px;
              }
            }

            &.nav-users {
              ul.nav-dropdown-items {
                top: 0px;
              }
            }

            &:last-child {
              ul.nav-dropdown-items {
                margin-top: -150%;
              }
            }

            &.nav-settings {
              ul.nav-dropdown-items {
                top: -100px;
              }
            }

          }
        }
      }

      ul.nav-dropdown-items {
        /*padding: 20px 20px 20px 20px;*/
        padding: 0 10px 10px 10px;
        width: 200px;
        margin-top: -30%;

        li.nav-item {
          width: 180px;
          padding: 0 0 0 0;

          .nav-group-title {
            font-family: 'Roboto Condensed', sans-serif;
            font-size: 14px;
            color: #6382a9;
            padding: 0 10px 8px 10px;
            margin-top: 10px;
          }

          .nav-link {
            padding: 4px 10px 4px 10px;
            width: 100%;
            margin-bottom: 3px;
            font-size: 14px;

            .nav-icon {
              width: 24px;
              padding: 2px 6px 0 0;
            }

            &.open {
              &.active {
                background: $sidebar-nav-dropdown-bg;

                &:hover {
                  background: $sidebar-nav-link-hover-bg-item;
                }

              }
            }

            &.router-link-exact-active {
              background: $sidebar-nav-link-hover-bg-item-active !important;

              &:hover {
                background: $sidebar-nav-link-hover-bg-item !important;
              }

            }

            &:hover {
              background: $sidebar-nav-link-hover-bg-item;
            }

            .nav-icon {
              font-size: 16px;
              margin: 0;
            }
          }
        }
      }
    }

  }
}

@media (min-width: 992px) {
  .header-fixed .app-header {
    position: fixed;
    z-index: 1020;
    width: 100%;
    -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    color: #263c46;
  }

}

@media (min-width: 768px) {
  html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
    margin-left: 100px;
  }

  .sidebar-minimized .sidebar .sidebar-nav {
    .nav {
      .nav-item {
        &.single-nav {
          .nav-link {
            height: 46px;
            line-height: 26px;

            &.active {
              color: #fff;
              background: $sidebar-nav-dropdown-bg;
            }
          }
        }
      }
    }
  }

}
