.form-group {
  .multiselect {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 46px;
    text-align: left;
    color: #35495e;

    &.multiselect--active {
      .svg-inline--fa {
        transform: rotate(180deg);
      }
    }

    .toggle-hold {
      position: absolute;
      top: 1px;
      right: 1px;
      width: 40px;
      height: 44px;
      padding: 11px 15px 4px 8px;
      text-align: center;

      .svg-inline--fa {
        color: #000000;
      }
    }

    .multiselect__select {
      width: 40px;
      height: 44px;
      right: 1px;
      top: 1px;
      padding: 4px 8px;
      text-align: center;


    }

    .multiselect__tags {
      min-height: 46px;
      display: block;
      padding: 11px 40px 0 13px;
      border: 1px solid #e7ebf1;
      background: #fff;
      font-size: 14px;
      font-weight: 400;
      color: #312f34;
      border-radius: 2px;
      letter-spacing: .7px;

      .multiselect__single {
        strong {
          font-family: 'Titillium Web', sans-serif;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .multiselect__placeholder_m {
        color: #b3b3b3;
        display: inline-block;
        margin-bottom: 0;
        padding-top: 2px;
        padding-left: 6px;
        font-size: 14px;
      }

    }

    .multiselect__content-wrapper {
      margin-top: -1px;
      display: block;
      background: #fff;
      border: none;
      border-top: 2px solid #7a7cbd;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
      padding: 0 5px 6px 5px;

      .multiselect__option--selected {
        background: #dff2ff;
        color: #7a7cbd;
        font-weight: 400;
      }

      ul.multiselect__content {
        li.multiselect__element {
          margin-top: 5px;

          span.multiselect__option {

            &:after {
              content: "";
            }

            display: block;
            min-height: 40px;
            line-height: 16px;
            text-decoration: none;
            text-transform: none;
            vertical-align: middle;
            position: relative;
            cursor: pointer;
            white-space: pre-wrap;
            color: #312f34;
            font-size: 14px;
            padding: 11px 12px 12px 45px;


            &:before {
              border-radius: 100%;
              width: 13px;
              height: 13px;
              top: 13px;
              left: 18px;
              border: 1px solid #b3b3b3;
              background-color: #fff;
              content: "";
              position: absolute;
            }

            &.multiselect__option--selected {
              background: #7a7cbd4d;
              color: #4547ad;
              font-weight: 400;

              &:before {
                border: 2px solid #7a7cbd;
              }

              span {
                position: relative;

                &:before {
                  position: absolute;
                  content: "";
                  display: block;
                  border-radius: 100%;
                  width: 5px;
                  height: 5px;
                  top: 7px;
                  left: -23px;
                  border: 1px solid #7a7cbd;
                  background-color: #7a7cbd;
                }

              }
            }

            &.multiselect__option--highlight {
              background: rgba(150, 152, 230, 0.1);

              &.multiselect__option--selected {
                background: rgba(150, 152, 230, 0.3);
              }
            }

          }
        }
      }
    }

  }
}

.multiselect__tags-wrap {
  .multiselect__tag {
    position: relative;
    display: inline-block;
    padding: 6px 30px 6px 10px;
    border-radius: 3px;
    margin-right: 8px;
    color: #0094fc;
    line-height: 1;
    background: #dff2ff;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    margin-top: -1px;

    .multiselect__tag-icon {
      cursor: pointer;
      margin-left: 9px;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      font-weight: 700;
      font-style: normal;
      width: 24px;
      text-align: center;
      line-height: 24px;
      -webkit-transition: all .2s ease;
      transition: all .2s ease;
      border-radius: 3px;

      &:after {
        color: #0094fc;
        font-size: 16px;
      }

      &:hover {
        background: #0094fc;

        &:after {
          color: #ffffff;
          font-size: 16px;
        }
      }
    }

  }
}



.option__img {
  &.option__img-right {
    display: block;
    position: absolute;
    right: 0;
    top: 9px;
  }

  &.option__img-selected-right {
    position: absolute;
    right: 1px;
    top: 0;
  }

}

.multiselect__spinner {
  position: absolute;
  right: 30px;
  top: 5px;
  width: 48px;
  height: 35px;
  background: #fff;
  z-index: 100;
}

.multiselect__spinner:before,
.multiselect__spinner:after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border-color: #0094fc transparent transparent;
  border-style: solid;
  border-width: 2px;
  box-shadow: 0 0 0 1px transparent;
}

.form-group-search {
  margin-bottom: 0;
}


.input-m-select {
  min-width: 253px;
  max-width: 253px;

  ul.multiselect__content {
    li.multiselect__element {
      span.multiselect__option {
        &:after {
          content: "";
        }
      }
    }
  }

  &.input-h-select {
    width: 70%;
    min-width: 70%;
    max-width: 70%;
  }

  &.input-w-select {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  &.input-salary-select {
    width: 153px;
    min-width: 153px;
    max-width: 153px;
    float: left;

    .multiselect__tags {
      border: 1px solid #b3b3b3 !important;
    }
  }
}

.option__desc {
  display: block;
  position: absolute;
  top: -4px;
  left: -45px;
  font-size: 16px;
  width: 100%;

  .option__title {
    &:before {
      display: none !important;
      content: " ";
    }
  }

  .option__small {
    display: none;
    color: #b2b3b4;
    &:before {
      display: none !important;
      content: " ";
    }
  }

}

.form-group-search {
  height: 46px;
  max-width: 100%;
  width: 280px;

  &.form-group-search-city {
    max-width: 380px;
    width: 380px;
  }

  &.form-group-search-category {
    max-width: 280px;
    width: 280px;
    margin-right: 16px !important;
  }

  .input-w-select {
    height: 46px;
    max-height: 46px;
    position: relative;
    width: 100%;
  }

  .multiselect .multiselect__tags {
    min-height: 46px;
    display: block;
    padding: 11px 40px 0 13px;
    border-radius: 0;
    border: 1px solid #ffffff;
    background: #fff;
    font-size: 14px;
    font-weight: 400;
    color: #312f34;
    letter-spacing: .7px;

    input {
      margin-top: 2px;
      min-height: 20px;
      line-height: 20px;
      margin-bottom: 6px;
      border: none;
      vertical-align: top;
    }
  }

  .multiselect__single {
    padding-left: 5px;
    margin-bottom: 0px;
    margin-top: 1px;
  }

  .multiselect__content-wrapper {
    .multiselect__content {
      .multiselect__element {
        overflow: hidden;
        position: relative;
        .multiselect__option {
          .option__desc {
            position: absolute;
            margin-top: 0;
            min-height: 46px;
            left: -12px;
            top: -3px;
            overflow: hidden;
            .option__title {
              overflow: hidden;
              min-height: 46px;
            }
          }
        }
      }
    }
  }

}