.card-divider {

  padding: 0.5rem 0.5rem 0.1rem 1rem;
  margin-bottom: 20px;
  border-bottom: 1px dotted #dcdcdc;

  .card-divider-title {
    font-family: 'Titillium Web', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #939598;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-transform: uppercase;
    margin-bottom: 0.46em;
    text-align: right;

    &.a-left {
      text-align: left;
    }
  }
}
