.list-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .sidebar-nav-container {
    width: 24%;
    .sub-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      .sidebar-nav {
        width: 50%;
        height: 100vh;
        padding: 60px 10px 10px 10px;

        .sidebar-title {
          background: #7a7cbd;
          color: #ffffff;
          -webkit-box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.08);
          box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.08);
          border-radius: 3px;
          border-bottom: none;
          border-top: none;
          font-family: 'Titillium Web', sans-serif;
          font-size: 14px;
          font-weight: 400;
          padding: 0.5rem 0.75rem 0.65rem 0.75rem;
        }

        .sidebar-choices {
          background: #ffffff;
          color: #7a7cbd;
          -webkit-box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.08);
          box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.08);
          border-radius: 3px;
          border-bottom: none;
          border-top: none;
          font-family: 'Titillium Web', sans-serif;
          font-size: 14px;
          font-weight: 400;
          padding: 0.5rem 0.75rem 0.65rem 0.75rem;
          .value {
            margin-top: 5px;
            margin-bottom: 5px;
          }
        }

      }
    }
  }
  .data-container {
    width: 76%;
  }
}


