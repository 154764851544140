$sidebar-minimized-width:       100px;
$navbar-brand-minimized-width:  100px;
$sidebar-width:                 200px;
$sidebar-bg:                    #304056;
$navbar-brand-minimized-bg:     #304056;
$sidebar-nav-link-icon-color:   #aaa6a0;
$sidebar-nav-link-hover-bg:     #222c3c;
$sidebar-nav-link-hover-bg-item:  #2d3b50;
$sidebar-nav-link-hover-bg-item-active : #364760;

$sidebar-nav-dropdown-bg: #222c3c;
$sidebar-nav-link-active-bg: #c2c2c2;


$body-bg:                       #fafafa;

// Breadcrumbs
$breadcrumb-bg: #fff;
$breadcrumb-margin-bottom: 1.5rem;

$input-line-height: 1.5;
$input-line-height-sm: 1.5;
$input-line-height-lg: 1.5;
$btn-line-height: 1.5;
$btn-line-height-sm: 1.5;
$btn-line-height-lg: 1.5;

$custom-select-padding-y: .375rem;
$custom-select-padding-x: .75rem;

$custom-control-indicator-size-body: 1.5rem;
$custom-control-gutter-body: 2.2rem;
$custom-control-indicator-bg-body: #5eba00;
