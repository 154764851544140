.card-select {
  width: 100%;
  min-height: 49px;
  border: 2px solid #7a7cbd;
  background: #ffffff;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  padding: 10px 30px 8px 18px;

  .card-label {
    font-weight: 600;
    font-size: 16px;
    color: #7a7cbd;
    line-height: 25px;
    /*color: rgba(244,244,245,.6)*/
  }

  &:hover {
    border: 2px solid #474d84;
    .card-label {
      color: #474d84;
    }
  }

  &.active {
    border: 2px solid #7a7cbd;
    background: #7a7cbd;
    color: #ffffff;

    .card-label {
      color: #ffffff;
    }

    &:before {
      content: "";
      width: 26px;
      height: 26px;
      position: absolute;
      top: -10px;
      right: -10px;
      background: #ffffff;
      border-radius: 99em;
      border: 2px solid #ffffff;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
      box-shadow: 0px 0px 3px 0px rgba(122, 124, 189, 0.6);
    }
    &:after {
      content: "";
      background-image: url('../../images/check-icon.svg');
      background-size: contain;
      width: 16px;
      height: 16px;
      position: absolute;
      top: -4px;
      right: -5px;
    }
    &:hover {
      border: 2px solid #7a7cbd;
      color: #ffffff !important;
    }
  }



}
