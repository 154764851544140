.action-footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 54px;
  display: block;
  z-index: 100;
  -webkit-box-shadow: 0px -3px 4px rgba(0, 0, 0, 0.14);
  box-shadow: 0px -3px 20px rgba(136, 136, 136, 0.14);
  background: #ffffff;
  padding: 10px 30px 10px 120px;

      .btn {
        line-height: 1;
        font-family: 'Titillium Web', sans-serif;
        font-size: 16px;
        font-weight: 400;
        padding: 8px 40px 10px 40px;
        &.btn-success {
          text-align: center;
        }

        &.btn-danger {
          text-align: center;
        }

        &.btn-warning {
          text-align: center;
          color: #fff;
          background-color: #ff5722;
          border-color: #ff5722;

          &:hover {
            background-color: #d9480b;
            border-color: #d9480b;
          }

        }

        &.bnt-save-continue {
          margin-right: 10px;
        }

        &.bnt-send {
          margin-right: 10px;
          background-color: rgb(122, 124, 189);
          border-color:rgb(122, 124, 189);
        }

        &.btn-secondary {
          text-align: center;
        }
      }

}
