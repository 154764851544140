.settings-body-wrap {
  background: #eff3f6;
  .settings-container {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 40px;
    max-width: 1000px;
    display: flex;
    .settings-container-header {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 5px;
      -webkit-animation: fadeIn;
      animation: fadeIn;
      -webkit-animation-duration: .25s;
      animation-duration: .25s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin: 40px 0 20px;
      width: 100%;
      .card-title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-bottom: 0;
        padding-right: 12px;
        color: #000;
        font-weight: 700;
        font-size: 24px;
      }
    }
    .settings-container-body {
      margin-bottom: 0;
      -webkit-animation-delay: .05s;
      animation-delay: .05s;
      padding: 0!important;
      margin-bottom: 34px;
      padding: 40px;
      border: 1px solid rgba(168,198,223,.4);
      border-radius: 2px;
      background: #fff;
      -webkit-box-shadow: 0 3px 4px 0 rgba(117, 134, 156, 0.1);
      box-shadow: 0 3px 4px 0 rgba(117, 134, 156, 0.1);
      -webkit-animation: fadeInUp;
      animation: fadeInUp;
      -webkit-animation-duration: .4s;
      animation-duration: .4s;
      -webkit-animation-fill-mode: backwards;
      animation-fill-mode: backwards;
      width: 100%;
      .settings-container-inner {
        padding: 28px 34px 28px;
        .col-form-label {
          text-align: right;
          font-family: 'Titillium Web', sans-serif;
          font-size: 14px;
          font-weight: 600;
          padding-top: calc(0.355rem + 1px);
          padding-bottom: calc(0.385rem + 1px);
          padding-right: 20px;
          color: #1c293b;
        }
        .ck-editor__editable_inline {
          min-height: 400px;
        }
        .value-wrap {
          &.small-wysiwyg {
            color: #000000;
            .ck-editor__editable_inline {
              min-height: 100px;
            }
          }
        }
      }
      &.template-list {
        .settings-container-inner {
          .value-wrap {
            border-bottom: 1px solid #e3e8ef;
            padding-bottom: 10px;
            padding-top: 10px;

            &:last-child {
              border-bottom: none;
              padding-top: 10px;
              padding-bottom: 0;
            }
            .value {
              .opt-content {
                position: relative;
                .opt-title {
                  margin-bottom: 3px;
                  font-weight: 700;
                  font-size: 16px;
                  color: #000;
                  text-decoration: none;
                  transition: all .2s ease;
                  &:hover {
                    text-decoration: none;
                  }
                }
                .opt-desc {
                  color: #75869c;
                  font-size: 14px;
                  text-decoration: none;
                  transition: all .2s ease;
                  &:hover {
                    text-decoration: none;
                  }
                }
                .opt-nav-icon {
                  position: absolute;
                  right: 20px;
                  top: 31%;
                  .svg-inline--fa {
                    transition: all .2s ease;
                    color: #0a0c0d;
                    font-size: 18px;
                  }
                }
              }
              &:hover {
                text-decoration: none !important;
                .opt-content {
                  .opt-title {
                    color: #a776b9;
                  }
                  .opt-nav-icon {
                    .svg-inline--fa {
                      color: #a776b9;
                    }
                  }
                }
              }
            }

          }
        }
      }
    }
    &.email-template-container {
      max-width: 100%;
    }
  }
}

.setting-description {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #75869c;
  margin-bottom: 2px!important
}

.setting-divider {
  margin: 11px auto 1px auto;
  height: 1px;
  border-top-width: 1px;
  border-color: #e3e8ef;
  display: block;
  border-top-style: solid;
  width: 100%;
}

.setting-e-template {
  margin-bottom: 5px;
}

.setting-e-divider {
  margin-bottom: 10px !important;
}

.card-e-body {
  padding-left: 19px;
  padding-top: 15px;
}
