.vue-tabs {
  .left-vertical-tabs {
    width: 13%;
    height: 100vh;
    height: calc(100vh - 55px);
    .nav {
      margin-bottom: 0;
      margin-top: 50px;
      list-style: none;
      padding: 24px 20px 20px 20px;

      li {
        width: 100%;
      }
    }

  }

  .nav-pills > li > a {
    border-radius: 4px;
    text-align: left;
    font-family: 'Titillium Web', sans-serif;
    font-size: 16px;
    font-weight: 400;
    padding: 6px 12px 7px 12px;

    span.title {
      justify-content: left;
      text-align: left;
    }

  }

  .tab-content {
    width: 87%;
    padding: 60px 30px 100px 30px;
  }
}

.vue-dialog {
  /*background-color: rgb(229, 238, 255);*/
  .attribute-tabs {
    &.vue-tabs {
      .left-vertical-tabs {
        width: auto;
        .nav {
          margin-bottom: 0;
          margin-top: 0;
          list-style: none;
          padding: 24px 0 20px 0;

          li {
            width: 100%;
            a {
              border-radius: 4px 0 0 4px;
              text-align: left;
              font-family: 'Titillium Web', sans-serif;
              font-size: 16px;
              font-weight: 400;
              padding: 6px 12px 7px 12px;
            }
          }
        }
      }
      .tab-content {
        border-radius: 4px;
        margin-top: -1px;
        margin-left: 0;
        /*background: rgb(229, 238, 255); */
        background: none;
        /*border: 1px solid rgb(229, 238, 255);*/
        border: none;
        padding: 1px 1px 1px 1px;
        width: auto;
        margin-bottom: 20px;

        .attribute-tab-content {
          border-radius: 1px;
          background: #ffffff;
          padding: 10px 0 0 0;
          height: auto;
          min-height: 400px;
          &.wrap-tab-content {
            padding: 20px 0 11px 0;
          }
        }
      }
    }
  }

}

