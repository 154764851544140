.card-body {
  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    tr {
      -webkit-box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.12);
      box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.12);
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      border-radius: 3px;

      &:hover {
        background-color: transparent;
        -webkit-box-shadow: 0 0px 10px 0 rgba(122, 124, 189, 0.3);
        box-shadow: 0 0px 10px 0 rgba(122, 124, 189, 0.3);
      }

      td {
        border-top: none;
        padding: 0.8rem 0.75rem 0.55rem 0.75rem;
        .custom-switch {
          margin-top: 1px;
        }
/*        &.data-image {
          padding: 0.55rem 0.75rem 0.55rem 0.75rem;
        }*/
      }
    }

    thead {
      tr {
        background: -webkit-gradient(
          linear,
          left top,
          right top,
          from(#7a7cbd),
          to(#a976b9)
        );
        background: linear-gradient(90deg, #7a7cbd, #a976b9);
        color: #ffffff;
        -webkit-box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.08);
        box-shadow: 0 0px 10px 0 rgba(36, 37, 38, 0.08);
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        th {
          border-bottom: none;
          border-top: none;
          font-family: 'Titillium Web', sans-serif;
          font-size: 14px;
          font-weight: 400;
          padding: 0.5rem 0.75rem 0.65rem 0.75rem;

          &:first-child {
            border-left: 2px solid #7a7cbd;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-bottomleft: 3px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }

          &:last-child {
            border-right: 2px solid #a976b9;
            -webkit-border-top-right-radius: 3px;
            -webkit-border-bottom-right-radius: 3px;
            -moz-border-radius-topright: 3px;
            -moz-border-radius-bottomright: 3px;
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            padding-right: 0.85rem;
          }
        }
      }
    }

    tbody {
      tr {
        border-left: 2px solid #7a7cbd;
        td {
          &:first-child {
            border-left: 2px solid #7a7cbd;
            -webkit-border-top-left-radius: 3px;
            -webkit-border-bottom-left-radius: 3px;
            -moz-border-radius-topleft: 3px;
            -moz-border-radius-bottomleft: 3px;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }

          font-family: 'Titillium Web', sans-serif;
          font-size: 14px;
          font-weight: 400;

          &.data-name {
            font-weight: 400;
            padding: 0.65rem 0.75rem 0.55rem 0.75rem;
            a {
              text-decoration: none;
              /*color: #7a7cbd;*/
              /*color: #6a6b9c;*/
              /*color: #272738;*/
              color: #000000;
              font-size: 16px;
              &:hover {
                /*color: #6a6b9c;*/
                color: #6a6b9c;
              }
            }
          }

          &.data-position {
            font-weight: 600;
          }

          &.actions {
            padding: 0.55rem 0.75rem 0.55rem 0.75rem;

            .btn-primary {
              color: #fff;
              background-color: #a776b9;
              border-color: #a776b9;
            }

            .btn-danger {
              color: #fff;
              background-color: #e9573f;
              border-color: #e9573f;
            }
          }
        }
      }
    }
  }
}

.list-page {
  .b-table-empty-row {
    td {
      .my-2 {
        margin-bottom: 1.5rem !important;
        color: #9e77bc;
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

.data-identifier-block-html {
  .v1email-body-container {
    border: none !important;
  }
  table {
    border-collapse: separate;
    border-spacing: 0;
    tbody {
      border: none;
      border-radius: 0;
    }
    tr {
      box-shadow: none !important;
      border: none !important;
      border-radius: 0 !important;
      td {
        border: none !important;
      }
    }
  }
}
