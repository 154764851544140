
.dg-backdrop {
  background-color: rgba(0, 0, 0, 0.2);
}

.dg-main-content {
  padding: 30px 35px 30px 35px;
  border-radius: 4px;
  margin: 50px auto;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);

  .dg-content-body {
    border-bottom: none;
    padding-bottom: 12px;
    strong {
      font-weight: 600;
    }
  }

  .dg-content-footer {
    .dg-btn {
      display: inline-block;
      position: relative;
      min-width: 80px;
      padding: 9px 24px 9px 24px;
      border-radius: 3px;
      outline: 0;
      border: 1px solid #636363;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      font-size: 15px;
      font-weight: 600;
      color: #ffffff;
      background: #636363;
      @include hover-focus {
        opacity: .8;
      }
      &.dg-btn--cancel {
        color: #636363;
        background: #ffffff;
        border: 1px solid #636363;
      }
      &.dg-btn--ok {
        color: #f90000;
        background: #ffffff;
        border: 1px solid #f90000;
        @include hover-focus {
          opacity: .7;
        }
      }
    }
  }

}

.vue-dialog-min{
  .card-body {
    min-height: 400px;
  }
}
