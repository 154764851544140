.body-container {

  .custom-control {
    position: relative;
    display: block;
    min-height: 2.10625rem;
    padding-left: 1.5rem;
  }

  .custom-control-label {
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    font-family: Roboto,sans-serif;
    font-size: 15px;
    font-weight: 400;

    // Background-color and (when enabled) gradient
    &::before {
      position: absolute;
      top: (
              ($font-size-base * $line-height-base - $custom-control-indicator-size-body) / 2
      );
      left: -$custom-control-gutter-body;
      display: block;
      width: $custom-control-indicator-size-body;
      height: $custom-control-indicator-size-body;
      pointer-events: none;
      content: '';
      user-select: none;
      background-color: $custom-control-indicator-bg;
      @include box-shadow($custom-control-indicator-box-shadow);
    }

    // Foreground (icon)
    &::after {
      position: absolute;
      top: (
              ($font-size-base * $line-height-base - $custom-control-indicator-size-body) / 2
      );
      left: -$custom-control-gutter-body;
      display: block;
      width: $custom-control-indicator-size-body;
      height: $custom-control-indicator-size-body;
      content: '';
      background-repeat: no-repeat;
      background-position: center center;
      background-size: $custom-control-indicator-bg-size;
    }
  }
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: rgb(122, 124, 189);
  border: 1px solid rgb(122, 124, 189);
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}