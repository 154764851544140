
// Tabler bootstrap variables
@import "./vendor/tabler/scss/variables";

@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Titillium+Web:400,600,700&subset=latin-ext');

// Custom Variables
@import "variables";

// Thème CoreUI
@import "~@coreui/coreui/scss/coreui";
@import "~bootstrap-vue/dist/bootstrap-vue.css";

// Tabler components
@import "./vendor/tabler/scss/dashboard/functions";
@import "./vendor/tabler/scss/dashboard/cards";
@import "./vendor/tabler/scss/dashboard/tag";
@import "./vendor/tabler/scss/dashboard/forms/custom-switch";
@import "./vendor/tabler/scss/dashboard/fonts/feather";

// Plugins
@import "~sweetalert2/src/sweetalert2";
@import "~noty/src/noty";
@import "~noty/src/themes/bootstrap-v4";
@import "~sweetalert2/src/sweetalert2";
@import "~flatpickr/dist/flatpickr.css";


// Components
@import "./buttons";

// RTL
@import "./bootstrap-rtl";

//Sidebar
@import './sidebar/_sidebar';

//Layout
@import './layout/_style';
@import './layout/_table';
@import './layout/_modal';
@import './layout/_tabs';
@import './layout/_action-header';
@import './layout/_action-footer';
@import './layout/_nav-list';
@import './layout/_attributes';
@import './layout/_translations';
@import './layout/data-sidebar';
@import './layout/_dashboard';

//Components
@import './components/checkbox';
@import './components/multiselect';
@import './components/card-select';
@import './components/drag';
@import './components/dialog';
@import './components/badge';
@import './components/card-divider';
@import './components/media';
@import './components/settings';
@import './components/dropdown';
@import './components/ui';
@import './components/actions';

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

[v-cloak] {
  display: none;
}

.intl-tel-input {
  width: 100%;
}

.alert.alert-top {
  border: none;
  border-radius: 0;
  margin-bottom: 0;
}

.custom-control-indicator, .custom-control-description, .switch-label, .switch-handle {
  margin-top: .25rem;
}

.flatpickr-calendar {
  border-radius: 0;
}

body .v-select {
  .selected-tag {
    background-color: #20a8d8;
    border-color: #20a8d8;
    color: #fff;

    .close {
      color: #fff;
      text-shadow: none;
      opacity: 1;
    }
  }

  .dropdown-toggle, .selected-tag {
    border-radius: 0;
  }
}

.table th {
  white-space: nowrap;
}

.nowrap {
  white-space: nowrap;
}
